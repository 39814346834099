import React, { useEffect, useState } from "react";
import "./ConfirmacionDatos.css";
import lineaPunteada from "../Imagenes/linea-punteada.svg";
import Navbar from "../Navbar/Navbar";

export const Politicas = (props) => {
  const { actualizarPagina, logoCompany } = props;

  const [respuesta, setRespuesta] = useState([]);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [idUser, setIdUser] = useState();
  const [phone, setPhone] = useState();
  const [error, setError] = useState(false);

  // obtengo info del localStorage, por eso se guarda en una constante.
  const id = localStorage.getItem("idHorarioSelec");
  const check_in = localStorage.getItem("checkIn");
  const check_out = localStorage.getItem("checkOut");
  const table_name = localStorage.getItem("tableName");
  const time = localStorage.getItem("time");
  const fecha = localStorage.getItem("Fecha");

  const nombreSucursal = localStorage.getItem("nombreSucursal");
  const addressSucursal = localStorage.getItem("addressSucursal");

  const saveInfoLocalStorage = () => {
    actualizarPagina();
  };

  useEffect(() => {
    try {
      const saveInfoLocal = localStorage.setItem("respuesta");
      let parsedRespuesta;
      if (!saveInfoLocal) {
        // si no-...
        localStorage.setItem("respuesta", JSON.stringify(respuesta));
        parsedRespuesta = respuesta; // respuesta es el estado
      } else {
        parsedRespuesta = JSON.parse(saveInfoLocal.getItem("respuesta"));
      }
      setRespuesta(parsedRespuesta);
    } catch {
      setError(error);
    }
  }, []);

  return (
    <>
      <div className="encabezado"></div>
      <div
        className="containerPrincipal"
        style={{ marginTop: "190px", "z-index": "100" }}
      >
        <section className="containerPoliticas">
          <div className="element-info">
            <h1>Políticas de reserva</h1>
          </div>
        </section>
        <section className="containerPoliticas">
          <div className="element-info">
            <ul>
              <li>
                No se permite el ingreso de comida o bebidas, salvo sea torta o
                postre para celebrar una fecha especial.
              </li>
              <li>
                Cancelación de la reservación: La cancelación por parte del
                usuario no le ocasionará gastos de cancelación anticipada.
              </li>            
              <li>
                Toda solicitud especial (platos especiales, ubicación de mesa,
                etc.) debe ser informada de manera explícita al Restaurante y
                será válida tras la confirmación expresa de la misma por el
                departamento de reservación.
              </li>
              <li>
                Las reservas podrán hacerse hasta 30 minutos antes de su visita.
                Las reservas que se hagan de manera telefónica quedaran
                inmediatamente confirmadas.
              </li>
              <li>
                El cliente puede ingresar bebidas alcohólicas siempre y cuando
                estén estampilladas y se acuerde, previamente, el valor del
                descorche.
              </li>
              <li>
                Llegar puntual. Después de 15 min se levanta la reserva.
              </li>
            </ul>
          </div>
        </section>
      </div>

      <section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <button
          className="boton"
          onClick={saveInfoLocalStorage}
          style={{
            color: "#FFFFFF",
            margin: "40px",
            border: "1px solid #28304A",
            background: "#28304A",
            padding: "9px",
            cursor: "pointer",
            width: "272px",
            borderRadius: "12px",
          }}
        >
          Aceptar Políticas
        </button>
      </section>
    </>
  );
};
