import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import lineaPunteada from "../Imagenes/linea-punteada.svg";

import marcaVerificacion from "../Imagenes/confirmacion.svg";
import phoneImg from "../Imagenes/phone.svg";
import tarjetaCredito from "../Imagenes/tarjetaCredito.svg";
import correo from "../Imagenes/correo.svg";
import ubicacion from "../Imagenes/ubicacion.svg";

import "./ConfirmarDatos.css";

export const ConfirmarDatos = props => {

    const {actualizarPagina,logoCompany} = props;
    const [urlBase, setUrlBase] = useState("https://encuesta.api.pleizt.com");
    const [uuid, setUuid] = useState("650a57cc733b8");

    // const [error, setError] = useState();

    const name = localStorage.getItem("name");
    const phone = localStorage.getItem("phone");
    const email = localStorage.getItem("email");
    const idUser = localStorage.getItem("idUser");

    const address = localStorage.getItem("address");

    const id = localStorage.getItem("idHorarioSelec");
    const check_in = localStorage.getItem("checkIn");
    const check_out = localStorage.getItem("checkOut");
    const table_name = localStorage.getItem("tableName");
    const time = localStorage.getItem("time"); // hora
    const fecha = localStorage.getItem("Fecha"); // fecha
    const motivo = localStorage.getItem("motivo"); // fecha
    const summary = localStorage.getItem("summary"); // fecha
    
    const nombreSucursal = localStorage.getItem("nombreSucursal"); 
    const addressSucursal = localStorage.getItem("addressSucursal");     
    const people = localStorage.getItem("people"); 
    
    const guardarReserva = async () => {
        console.log("enviar datos");
        try {
            let res = await fetch(urlBase+`/bookings`, {
                method: "POST",
                body: JSON.stringify({ // aqui se arma el json para enviarlo al api, de acuerdo a como esta en postman (POST)
                    date: fecha,
                    check_in: localStorage.getItem('checkIn'),
                    id_branch: localStorage.getItem('idSucursal'),
                    reason: motivo,
                    people: people,
                    summary: summary,                    
                    diner: {
                        id: 1, // de donde sale el id 
                        name: name,
                        email: email,
                        document: idUser,
                        phone: phone,
                        
                    }
                })
            })
            let resJSON = await res.json(); // contiene la respuesta del api
            console.log(resJSON);
            console.log(resJSON.message);

            if (resJSON.status === "success"){
                let respuestaApi = resJSON.data; // respuesta de la api cuando se envia el formulario completo
                // respuesta de la api cuando se envia el formulario completo
                localStorage.setItem("fechaCreacion", respuestaApi.date_create);
                localStorage.setItem("uuid", respuestaApi.uuid);
                localStorage.setItem("mensajeFinal", resJSON.message);
                
                actualizarPagina();
            } else {
                alert("No fue posible guardar la reserva");
            }
            // console.log(resJSON.data);
        } catch (err) {
            console.log(err);
        }
    }

    
    return (
        <>
            <div className='encabezado'>
         
            </div>

            <div className='containerPrincipal'>      
                <section className='containerConfirmacion'>
                    <div className='element-info'>
                        <h4 className='hora'>{check_in}</h4>
                        <p className='check'>Check IN</p>
                    </div>
                    <div className='element-info'>
                        <h5 className='duracionReserva'>{time}</h5>
                        <img src={lineaPunteada} alt="img"></img>
                    </div>
                    <div className='element-info'>
                        <h4 className='hora'>{check_out}</h4>
                        <p className='check'>Check OUT</p>
                    </div>
                </section>

                <div className='element-Confirmacion'>
                    <h3 className='confirmacion'> {nombreSucursal} {addressSucursal}</h3>  
                    <h3 className='confirmacion'>{fecha}, {table_name}</h3>
                </div>

                <section className='containerInfoCliente'>
                    <div className='container2'>
                        <img src={marcaVerificacion} alt="marcaVerificacion" ></img>
                        <div className='nombres'>
                            <h2>{name}</h2>
                            <h6>Pax Principal</h6>
                        </div>    
                    </div>
                    
                    <div className='container2'>
                        <img src={phoneImg} alt="phone" className='icons'></img>
                        <p>{phone}</p>
                    </div>
                    
                    <div className='container2'>
                        <img src={ubicacion} alt='ubicacion' className='icons'></img>
                        <p>{nombreSucursal} {addressSucursal} </p>
                    </div>

                    <div className='container2'>
                        <img src={correo} alt="correo" className='icons'></img>
                        <p>{email}</p>
                    </div>

                    <div className='container2'>
                        <img src={tarjetaCredito} alt="tarjetaCredito" className='icons'></img>
                        <p>{idUser}</p>
                    </div>
                </section>
            </div>

            <button 
                className="boton" 
                onClick={guardarReserva }
                style={{ margin: "40px", border: "1px solid #28304A", background: "#28304A", padding: "9px",
                    cursor: "pointer",
                    width: "272px",
                    borderRadius: "12px",
                    color: "#FFFFFF",
                }} 
            >
              Reservar 
            </button >
        </>
    )
}
