import { useEffect, useState } from "react";
import "./App.css";
import { ConfirmacionDatos } from "./componentes/ConfirmacionDatosCliente/ConfirmacionDatos";
import { ConfirmarDatos } from "./componentes/Confirmar datos/ConfirmarDatos";
import Formulario from "./componentes/Formulario/Formulario";
import { GraciasFin } from "./componentes/GraciasFin/GraciasFin";
import Navbar from "./componentes/Navbar/Navbar";
import { SeleccionHorario } from "./componentes/SelectHorario/SeleccionHorario";
import { useLocation } from "react-router-dom";
import { Politicas } from "./componentes/ConfirmacionDatosCliente/Politicas";
function AppReservas() {
  const [infoApi, setInfoApi] = useState([]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [urlBase, setUrlBase] = useState("https://encuesta.api.pleizt.com");
  const [uuid, setUuid] = useState("restaurantelasrocas");
  const urlApi = urlBase + `/bookings/` + uuid;
  useEffect(() => {
    (async function () {
      const res = await fetch(urlApi).then((res) => res.json());
      setInfoApi(res.data);
      setPaginaActual(0);
    })();
  }, [urlApi]);

  const [infoEspaciosDispo, setInfoEspaciosDispo] = useState([]);

  const [paginaActual, setPaginaActual] = useState();

  const obtenerEspaciosDisponibles = async () => {
    let indexSucursal = localStorage.getItem("indexSucursal"); // la posicion dentro de infoApi de la sucursal o tienda seleccionada por el usuario.
    let fecha = localStorage.getItem("Fecha");
    let personas = localStorage.getItem("Personas");
    let sucursal = infoApi.branchs[indexSucursal]; // esta buscando la informacion completa de la sucursal seleccionada. o esta buscando la sucursal seleccionada.

    localStorage.setItem("nombreSucursal", sucursal.name);
    localStorage.setItem("idSucursal", sucursal.id);
    localStorage.setItem("addressSucursal", sucursal.address);

    let idSucursal = sucursal.id;
    const urlEspaciosDispo =
      urlBase + `/bookings/spaces/${fecha}/${idSucursal}/${personas}`;
    //
    const res = await fetch(urlEspaciosDispo).then((res) => res.json());
    setInfoEspaciosDispo(res.data); // sea diferente a undefined sea muestra la pagina siguiente.

    actualizarPagina();
  };

  // Actualiza la pagina
  const actualizarPagina = () => {
    let posicion = paginaActual + 1;
    setPaginaActual(posicion);
  };

  let display = () => {
    if (infoApi.length != 0) {
      let data = infoApi.branchs;
      const addressList = data.map((item) => item.name);

      return (
        <>
          {(() => {
            if (paginaActual === 0) {
              return (
                <Formulario
                  addressList={addressList}
                  obtenerEspaciosDisponibles={obtenerEspaciosDisponibles}
                  logoCompany={infoApi.logo}
                />
              );
            }

            if (paginaActual === 1) {
              return (
                <SeleccionHorario
                  espaciosDisponibles={infoEspaciosDispo}
                  actualizarPagina={actualizarPagina}
                  logoCompany={infoApi.logo}
                />
              );
            }
            if (paginaActual === 2) {
              return (
                <Politicas
                  actualizarPagina={actualizarPagina}
                  logoCompany={infoApi.logo}
                />
              );
            }

            if (paginaActual === 3) {
              return (
                <ConfirmacionDatos
                  actualizarPagina={actualizarPagina}
                  logoCompany={infoApi.logo}
                />
              );
            }

            if (paginaActual === 4) {
              return (
                <ConfirmarDatos
                  actualizarPagina={actualizarPagina}
                  logoCompany={infoApi.logo}
                />
              );
            }

            if (paginaActual === 5) {
              return <GraciasFin logoCompany={infoApi.logo} />;
            }
          })()}
        </>
      );
    }
  };

  return (
    <div className="App">
      <>
        {(() => {
          return (
            <>
              <Navbar logoCompany={infoApi.logo} />
              {display()}
            </>
          );
        })()}
      </>
    </div>
  );
}

export default AppReservas;
