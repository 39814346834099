import React from "react";
import logoPlaizt from "../Imagenes/Logo.svg";
import decoracion from "../Imagenes/imgdecoration.svg";
import "./Navbar.css";

const Navbar = (props) => {
  const { logoCompany } = props;
  return (
    <>
      <nav className="navbar">
        <img
          src={logoCompany}
          alt="logoCompany"
          style={{
            position: "absolute",
            zIndex: "1",
            left: "50px",
            top: "20px",
            maxHeight: "120px",
          }}
        ></img>
        <img
          src={decoracion}
          alt="decoracion"
          style={{ position: "absolute", right: "0px", marginBottom: "20px",'z-index':"-10",width: "180px" }}
          className="decoracion"
        ></img>
      </nav>
    </>
  );
};

export default Navbar;
