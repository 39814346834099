import React, { useEffect, useState } from "react";
import "./ConfirmacionDatos.css";
import lineaPunteada from "../Imagenes/linea-punteada.svg";
import Navbar from "../Navbar/Navbar";
import Select from "react-select";
export const ConfirmacionDatos = (props) => {
  const { actualizarPagina, logoCompany } = props;

  const [respuesta, setRespuesta] = useState([]);

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [idUser, setIdUser] = useState();
  const [phone, setPhone] = useState();
  const [error, setError] = useState(false);

  // obtengo info del localStorage, por eso se guarda en una constante.
  const id = localStorage.getItem("idHorarioSelec");
  const check_in = localStorage.getItem("checkIn");
  const check_out = localStorage.getItem("checkOut");
  const table_name = localStorage.getItem("tableName");
  const time = localStorage.getItem("time");
  const fecha = localStorage.getItem("Fecha");

  const nombreSucursal = localStorage.getItem("nombreSucursal");
  const addressSucursal = localStorage.getItem("addressSucursal");

  const [motivo, settMotivo] = useState();
  const [textValue, setTextValue] = useState('');
  const options = [
    { value: "Cumpleaños", label: "Cumpleaños" },
    { value: "Cena romantica", label: "Cena romantica" },
    { value: "Despedida", label: "Despedida" },
    { value: "Festejo Empresarial", label: "Festejo Empresarial" },
    { value: "Sin motivo en especial", label: "Sin motivo en especial" },
  ];
  const onChangeSelect = (e) => {
    settMotivo(e.value);
  };
  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };
  const saveInfoLocalStorage = () => {
    if (name === undefined || email === undefined || phone === undefined) {
      alert("Por favor ingresar todos los datos");
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(email)) {
        if (
          !isNaN(phone) &&
          parseInt(phone, 10) >= 3000000000 &&
          parseInt(phone, 10) <= 7000000000
        ) {
          localStorage.setItem("name", name); // guarda info en el localStorage, no retorna nada
          localStorage.setItem("email", email);
          localStorage.setItem("idUser", idUser);
          localStorage.setItem("phone", phone);
          localStorage.setItem("motivo", motivo);
          localStorage.setItem("summary", textValue);
          actualizarPagina();
        } else {
          alert("Teléfono no válido : " + phone);
        }
      } else {
        alert("Correo electrónico no válido : " + email);
      }
    }
  };

  useEffect(() => {
    try {
      const saveInfoLocal = localStorage.setItem("respuesta");
      let parsedRespuesta;
      if (!saveInfoLocal) {
        // si no-...
        localStorage.setItem("respuesta", JSON.stringify(respuesta));
        parsedRespuesta = respuesta; // respuesta es el estado
      } else {
        parsedRespuesta = JSON.parse(saveInfoLocal.getItem("respuesta"));
      }
      setRespuesta(parsedRespuesta);
    } catch {
      setError(error);
    }
  }, []);

  return (
    <>
      <div className="encabezado"></div>

      <div className="containerPrincipal" id={id}>
        <section className="containerConfirmacion">
          <div className="element-info">
            <h4 className="hora">{check_in}</h4>
            <p className="check">Check IN</p>
          </div>
          <div className="element-info">
            <h5 className="duracionReserva">{time}</h5>
            <img src={lineaPunteada} alt="img"></img>
          </div>
          <div className="element-info">
            <h4 className="hora">{check_out}</h4>
            <p className="check">Check OUT</p>
          </div>
        </section>

        <div className="element-Confirmacion">
          <h3 className="confirmacion">
            {" "}
            {nombreSucursal} {addressSucursal}{" "}
          </h3>
          <h3 className="confirmacion">
            {fecha}, {table_name}
          </h3>
        </div>
      </div>

      <form className="form">
        <label className="label">Selecciona motivo de reserva</label>
        <Select
          id="sucursal"
          className="options"
          options={options}
          defaultValue={{
            label: "Sin motivo en especial",
            value: "Sin motivo en especial",
          }}
          onChange={onChangeSelect}
          theme={(theme) => ({
            ...theme,
            borderRadius: 12,
            width: 280,
            colors: {
              ...theme.colors,
              primary25: "#F3F2F4",
              primary: "#28304A",
              primary50: "#28304A",
            },
          })}
        />
        <label className="label">Nombre completo</label>

        <input
          type="text"
          className="inputConfirmacion"
          placeholder="Nombre completo"
          onChange={(e) => setName(e.target.value)}
        ></input>

        <label>Correo electrónico</label>
        <input
          type="text"
          className="inputConfirmacion"
          placeholder="mrdoe@doemail.com"
          onChange={(e) => setEmail(e.target.value)}
        ></input>

        <label>
          Número de identificación
          <h5 class="duracionReserva">Si acumulas puntos en el comercio.</h5>
        </label>
        <input
          type="number"
          className="inputConfirmacion"
          placeholder="8888888"
          onChange={(e) => setIdUser(e.target.value)}
        ></input>

        <label>Número de teléfono</label>
        <input
          type="number"
          className="inputConfirmacion"
          placeholder="Celular"
          onChange={(e) => setPhone(e.target.value)}
        ></input>

      <label>Comentarios</label>
      <textarea
        value={textValue}
        className="inputConfirmacionText"
        onChange={handleTextChange}
        placeholder=""
        rows={6} // Número de filas
        cols={40} // Número de columnas
      />
      </form>

      <button
        className="boton"
        onClick={saveInfoLocalStorage}
        style={{
          margin: "40px",
          border: "1px solid #28304A",
          background: "#28304A",
          padding: "9px",
          cursor: "pointer",
          width: "272px",
          borderRadius: "12px",
          color: "#FFFFFF",
        }}
      >
        Siguiente
      </button>
    </>
  );
};
